body {
  background-color: #fafafa;
}

.container {
  margin: 10px auto;
  width: 600px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.2);
}

.card:hover {
  opacity: 0.75;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  background-color: dodgerblue;
  border-radius: 5px;
  border: 1px solid slategray;
  color: #fff;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn:active {
  opacity: 0.75;
}

.input-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.input-container label {
  font-weight: 500;
}

.input-container input {
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 30px;
  padding: 0 5px;
}

.error {
  color: #f00000;
  font-size: small;
}
